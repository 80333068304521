.content[data-v-6a009865] {
  width: 300px;
  height: 460px;
  border: 1px solid #D7D7D7;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 0 20px;
  border-radius: 6px;
}
.head[data-v-6a009865] {
  height: 40px;
  background-color: #F5F7FA;
  padding: 8px;
  line-height: 24px;
  border-bottom: 1px solid #D7D7D7;
}
.selContent[data-v-6a009865] {
  padding: 10px 20px;
}
